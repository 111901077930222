import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Nav from 'components/Nav/Nav'

export default function Routes ({ currentUser, setCurrentUser }) {
  return <BrowserRouter>
    <Nav currentUser={currentUser} setCurrentUser={setCurrentUser} />
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/"><Redirect to="/instruments" /></Route>
        <Route path="/instruments" component={lazy(() => import('components/Instruments/Instruments'))} />
        <Route path="/settings" component={lazy(() => import('components/Settings/Settings'))} />
      </Switch>
    </Suspense>
  </BrowserRouter>
}

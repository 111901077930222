import React, { useState, useEffect } from 'react'
import Login from 'components/Login/Login'
import Router from 'components/Router/Router'
import API from 'utils/api'

export default function App () {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    API.get('users/current').then(({ user }) => setCurrentUser(user))
  }, [])

  if (currentUser === null) {
    return <div /> // TODO: Add loading screen
  }
  if (currentUser === undefined) {
    return <Login setCurrentUser={setCurrentUser} />
  }
  return <Router currentUser={currentUser} setCurrentUser={setCurrentUser} />
}

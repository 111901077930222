const API_ROOT = `${process.env.REACT_APP_BACKEND || 'http://localhost:8000'}`

export default class API {
  static fetch (path, params, options = {}) {
    options = { credentials: 'include', ...options }
    const suffix = Object.keys(params).length ? new URLSearchParams(params).toString() : ''
    return fetch(`${API_ROOT}/${path}/?${suffix}`, options).then(r => r.json())
  }

  static get (path, params = {}) {
    return API.fetch(path, params)
  }

  static delete (path, params = {}) {
    return API.fetch(path, params, {
      method: 'DELETE',
      headers: { 'X-CSRFToken': API.getCookie('csrftoken') },
    })
  }

  static post (path, data = {}, options = {}) {
    return API.fetch(path, '', {
      body: JSON.stringify(data),
      headers: { 'X-CSRFToken': API.getCookie('csrftoken') },
      method: 'POST',
      ...options,
    })
  }

  static link (path) {
    return `${API_ROOT}/${path}/`
  }

  static getCookie (name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }
}

import React, { useState, useEffect } from 'react'
import './Login.scss'
import API from 'utils/api'

export default function Login ({ setCurrentUser }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    API.get('users/login').then(() => setReady(true))
  }, [])

  function onSubmit (e) {
    e.preventDefault()
    API.post('users/login', { username, password }).then(({ error }) => {
      if (error) {
        setError(error)
      } else {
        API.get('users/current').then(({ user }) => setCurrentUser(user))
      }
    })
  }

  const { PUBLIC_URL } = process.env
  const errorClassName = error ? 'visible' : 'invisible'

  return <div className="Login">
    <img className="mb-2" src={`${PUBLIC_URL}/logo.png`} alt="" />
    <h1 className="mb-2">Indicator Matrix</h1>
    <form onSubmit={onSubmit}>
      <input
        autoComplete="email"
        autoFocus=""
        className="form-control"
        onChange={e => setUsername(e.target.value)}
        placeholder="Email address"
        required=""
        type="email"
        value={username}
      />
      <input
        autoComplete="password"
        className="form-control"
        onChange={e => setPassword(e.target.value)}
        placeholder="Password"
        required=""
        type="password"
        value={password}
      />
      <button className="btn btn-lg btn-primary w-100 mb-2" type="submit" disabled={!ready}>
        Sign in
      </button>
      <div className={errorClassName}>
        <div className="alert alert-danger" role="alert">{error || 'hidden'}</div>
      </div>
    </form>
  </div>
}

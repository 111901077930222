import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import API from 'utils/api'
import GridSize from 'components/GridSize'

export default function Nav ({ currentUser, setCurrentUser }) {
  const [collapse, setCollapse] = useState(true)

  const logout = () => {
    API.delete('users/login').then(({ user }) => setCurrentUser(user))
  }

  const onNavLinkClick = () => setCollapse(true)

  return <nav className="navbar navbar-expand-sm navbar-dark fixed-top bg-dark">
    <div className="container-fluid">
      <Link to="/" className="navbar-brand">
        Analytics &nbsp;
        <GridSize className="badge rounded-pill bg-light text-dark" />
      </Link>
      <button onClick={() => setCollapse(!collapse)} className="navbar-toggler" type="button">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`navbar-collapse ${collapse ? 'collapse' : ''}`}>
        <ul className="navbar-nav me-auto mb-2 mb-sm-0">
          <li className="nav-item">
            <NavLink activeClassName="active" to="/instruments" className="nav-link" onClick={onNavLinkClick}>
              Instruments
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="active" to="/settings" className="nav-link" onClick={onNavLinkClick}>
              Settings
            </NavLink>
          </li>
          {currentUser.is_staff &&
            <li className="nav-item">
              <a className="nav-link" target="_blank" rel="noreferrer" href={API.link('admin')}>Admin</a>
            </li>
          }
        </ul>
        <button onClick={logout} className="btn btn-outline-light" type="submit">
          Logout
        </button>
      </div>
    </div>
  </nav>
}

import React from 'react'

export default function GridSize (props) {
  return (
    <div {...props}>
      <span className="d-block d-sm-none">xs</span>
      <span className="d-none d-sm-block d-md-none">sm</span>
      <span className="d-none d-md-block d-lg-none">md</span>
      <span className="d-none d-lg-block d-xl-none">lg</span>
      <span className="d-none d-xl-block d-xxl-none">xl</span>
      <span className="d-none d-xxl-block">xxl</span>
    </div>
  )
}
